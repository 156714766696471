export function changeStates($target, country, successFn) {
  $.ajax('/addresses/state_list', {
    dataType: 'JSON',
    method: 'GET',
    data: {
      country: country
    }
  }).done(function(states) {
    const stateOptions = states.map(state => {
      return `<option value="${state[1]}">${state[0]}</option>` 
    }).join('')
    $target.html(stateOptions)
    successFn()
  })
}
