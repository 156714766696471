import { showAlert } from '../common/alert'

document.addEventListener("turbolinks:load", () => {
  $('.navbar-toggler, .mobile-menu-overlay').on('click', function() {
    $('body').toggleClass('mobile-menu-open')
  })

  if (document.cookie.indexOf("SBLCookiesAccepted=true")<0) {
    showAlert(
      'Cookies are required for this website to present certain basic features.' +
      'Click to <a class="cookie-accept">CONSENT</a> OR <a class="cookie-decline">DECLINE</a> their use.', 'warning'
    )
  }

  $("#flash .cookie-accept, #flash .cookie-decline").click(function(){
    $('#flash').removeClass('show')
  });

  $("#flash .cookie-accept").click(function(){
    document.cookie = "SBLCookiesAccepted=true; expires=Fri, 31 Dec 2100 23:59:59 GMT; path=/";
  });
})
