import { showAlert } from '../common/alert'
import { changeStates } from '../common/address'

document.addEventListener("turbolinks:load", () => {
  $('.line-item a[date-method="DELETE"]').on('ajaxSuccess', function() {
    $(this).parent('.line-item').remove()
  })

  $('#guest_checkout').on('change', function() {
    $('.membership-fields').toggleClass('d-none')
  })

  $('#shipping .form-control.country').on('change', function() {
    changeStates(
      $(this).parents('.address-wrapper').find('[id$="_state"]'),
      $(this).val(),
      () => {}
    )
  })

  $('#shipping .form-control').on('change', function() {
    $(this).parent('.form-group').removeClass('form-group-invalid')
    $(this).removeClass('is-invalid')
  })

  function updateCart() {
    $.ajax('/cart', {
      dataType: 'json',
      method: 'PATCH',
      data: {
        cart: {
          shipping_option_id: $('#cart_shipping_option_id').val()
        }
      }
    }).done((result) => {
      [
        'sub_total', 'discount', 'total', 'tax', 'current_shipping',
        'backorder_sub_total', 'backorder_discount', 'backorder_tax', 'backorder_shipping'
      ].forEach((key) => {
        $(`#shipping #${key}_value`).html(result.cart[key])
      })
    })
  }

  $('#cart_shipping_option_id').on('change', function() {
    updateCart(false)
  })

  $('.edit_line_item .form-control').on('blur', function() {
    $('.edit_line_item').submit()
  })

  $('#same_as_shipping').on('change', function() {
    if ($(this).is(':checked')) {
      ['line_1', 'line_2', 'city', 'zip', 'country'].forEach((key) => {
        $(`#cart_addresses_attributes_1_${key}`).val($(`#cart_addresses_attributes_0_${key}`).val())
      })
      // Reset states
      changeStates(
        $('#cart_addresses_attributes_1_state'),
        $('#cart_addresses_attributes_1_country').val(),
        () => {
          $(`#cart_addresses_attributes_1_state`).val($(`#cart_addresses_attributes_0_state`).val())
        }
      )
    }
  })

  $('#billing_address .form-control').on('change', function() {
    $('#same_as_shipping').prop('checked', false)
  })
})
