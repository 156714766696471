let dataTable;

document.addEventListener("turbolinks:load", () => {
  if ($('#orders_table').length == 0)
    return

  dataTable = $('#orders_table').DataTable({
    processing: true,
    serverSide: true,
    stateSave: true,
    ajax: "/orders",
    autoWidth: false,
    columns: [
      { data: "id" },
      { data: "created_at" },
      { data: "quantity" },
      { data: "total" },
      { data: "state" },
      { data: "tracking_number" },
      { data: "id", bSortable: false, className: 'text-right', render: function( data, type, row, meta ) {
        var html = `<a href="/orders/${row.id}" class="btn btn-info"><i class="fa fa-edit"></i></a>` +
                    `<a href="javascript:;" data-id="${row.id}" data-method="delete" class="btn btn-danger"><i class="fa fa-trash"></i></a>`
        return html
      } }
    ],
    columnDefs: [
      { orderable: true, targets: [0, 1] },
      { orderable: false, targets: '_all' }
    ],
    order: [[1, 'asc']]
  })

  $('#orders_table').on('click', '.btn-danger', function() {
    const id = $(this).data('id')
    $.ajax(`/orders/${id}`, {
      dataType: 'json',
      method: 'DELETE'
    }).done(function() {
      dataTable.draw(false)
    })
  })
})

document.addEventListener("turbolinks:before-cache", () => {
  if (typeof dataTable != 'undefined')
    dataTable.destroy()
})
